export default {
  // App level components texts
  header_text:
    'Verificación de identidad para reclamo de desempleo en una Post Office',
  header_text_sup: 'TM',
  enrollment_component_heading:
    'Próximos pasos para la verificación de identidad para las solicitudes de desempleo',
  reports_component_heading: 'Informe de verificación en ,persona de USPS',
  updatedTechnicalHeader_text: 'USPS in person verification technical report',
  of_text: 'de',
  back_text: 'ATRÁS',

  // UICP component
  uicp_header: 'Verificación de identidad para reclamo de desempleo',
  identity_text: 'Seleccione la mejor opción de verificación de identidad',
  online_radiobutton_label_text: 'Online - Login.gov',
  online_radiobutton_des_text:
    'Utilice su teléfono celular o computadora para subir fotos de sus documentos para verificar su identidad a través de Login.gov.',
  online_radiobutton_des_main_text:
    'Utilice Login.gov para cargar fotografías de sus documentos de identidad con su computadora y/o teléfono.',
  online_radiobutton_des_biometric_text:
    '{br}Necesitará un <b>número de teléfono válido</b> y un <b>teléfono inteligente</b> para completar la verificación de identidad a través de Login.gov.',
  online_radiobutton_des_unavailable_text:
    'La verificación de su identidad con Login.gov no está disponible actualmente.{br}{br}',
  online_radiobutton_doc_list_title: 'Documentos de identidad aceptados',
  online_radiobutton_doc_list_text_first: 'Documento de identidad estadual',
  important_message: ' ',
  usps_radiobutton_label_text: 'En-persona - Post Office<sup>TM</sup>',
  usps_radiobutton_des_text:
    'Lleve sus documentos de identidad a una oficina del Correo Postal que ofrezca servicios de verificación.',
  usps_radiobutton_des_unavailable_text:
    'La verificación de su identidad con USPS no está disponible actualmente.{br}{br}',
  usps_radiobutton_doc_list_title: '<b>Documentos de identidad aceptados</b>',
  usps_radiobutton_doc_list_text_first: 'Licencia de conducir',
  usps_radiobutton_doc_list_text_second:
    'Tarjeta de identificación emitida por su estado',
  usps_radiobutton_doc_list_text_third:
    'Tarjeta de identificación de dependiente militar o Tarjeta de Marino Mercante de la Guardia Costera de Estados Unidos (necesita otra identificación)',
  usps_radiobutton_doc_list_text_fourth:
    'Pasaporté estadunidense (necesita otra identificación)',

  workforce_radiobutton_label_text: ' ',
  workforce_radiobutton_des_text: ' ',
  workforce_radiobutton_doc_list_title: ' ',
  workforce_radiobutton_doc_list_text_first: ' ',
  workforce_radiobutton_doc_list_text_second: ' ',
  workforce_radiobutton_doc_list_text_third: ' ',
  workforce_radiobutton_doc_list_text_fourth: ' ',
  workforce_radiobutton_doc_list_text_fifth: ' ',
  workforce_radiobutton_doc_list_text_sixth: ' ',
  workforce_radiobutton_doc_list_text_seven: ' ',
  workforce_radiobutton_doc_list_text_eight: ' ',

  base_radiobutton_des_unavailable_text_first:
    'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado.{br}',
  base_radiobutton_des_unavailable_text_second:
    'Por favor, vuelva a intentarlo más tarde o elija un método de verificación diferente.{br}',
  base_radiobutton_des_unavailable_text_third:
    'Gracias por su paciencia y comprensión.',
  uicp_section_next_button_text: 'Próxima',
  uicp_identity_footer_text1:
    'El método escogido para verificar su identidad no afectará sus posibilidades de recibir beneficios de desempleo.',
  uicp_identify_footer_text2:
    'El personal estatal procesará su solicitud siguiendo todas las leyes y reglamentos aplicables.',
  uicp_identify_footer_text3:
    'Si necesita ayuda para verificar su identidad para su reclamo de desempleo o necesita ayuda para verificar su identidad:',
  scroll_to_top_text: 'Volver al principio',
  uicp_identity_footer_text:
    'El método escogido para verificar su identidad no afectará sus posibilidades de recibir beneficios de desempleo. El personal estatal procesará su solicitud siguiendo todas las leyes y reglamentos aplicables.',
  questions_para2: ' ',
  department_workforce: ' ',
  state_holiday: ' ',
  more_information: ' ',
  unemployment_question1: ' ',
  unemployment_question2:
    'Si usted tiene preguntas sobre los beneficios de desempleo o si necesita ayuda para verificar su identidad puede contactarnos a través de:{br}{br}',
  error_scroll_to_top_text: 'Volver al principio',

  // Logout component
  logout_title: 'Se ha cerrado sesión en login.gov',
  logout_intro:
    'Si ha realizado los pasos a continuación, puede cerrar esta ventana.',

  // Confirmation Component
  confirmation_link_item_third: 'Cerrar Sesión',
  confirmation_header:
    'Próximos pasos para la verificación de identidad de las solicitudes de desempleo',
  confirmation_name: ', su verificación de identidad ha sido enviada.',
  confirmation_checkmark: 'Compartimos su verificación de identidad con',
  confirmation_swa_id_text:
    'El código utilizado para verificar su identidad es el siguiente:',
  confirmation_next_step_text: 'Recordatorio',
  confirmation_steps_content_line_first:
    'If you have not yet called the UI Hotline, please call 1-123-123-1234 to have your claim processed.',
  confirmation_steps_content_line_second:
    'Register for work online using JobLink (JL) at ajljoblink.gov. Visit your local WDS office for assistance.',
  confirmation_Email_me_btn_text: ' ',
  confirmation_date_seperator: 'el',
  confirmation_state_title: ' ',
  confirmation_content_extra: ' ',
  confirmation_content_info: ' ',

  // Enrollment component
  post_office_visit_text: 'Confirmación de visita a la oficina de correos',
  enrollment_heading_text:
    'Visite Post Office<sup>TM</sup> antes de o para el dia ',
  enrollment_description_para1:
    'Está a punto de confirmar su identidad para su <b>reclamo de desempleo</b> con USPS. Recibirá una copia de este código de inscripción por correo electrónico. Imprima el correo electrónico y tráigalo con usted a Post Office<sup>TM</sup>. También puede mostrar una imagen de la pantalla del código de barras.',
  enrollment_code_bold_text: '<b>Código de inscripción </b>',
  enrollment_description_para2_boldText:
    ' Su código de barras y su inscripción vencerán después de ',
  enrollment_description_para2_boldText1: ' días.',
  enrollment_description_para2:
    'Si no verifica su identidad, es posible que se retrasen o nieguen sus beneficios y es posible que deba devolver los beneficios que haya recibido del Programa de Seguro de Desempleo.',
  print_this_pageBtn_text: 'Imprimir esta página',
  enrollment_different_verification_method_link:
    'Seleccione un método de verificación diferente',
  enrollment_personal_information_text: 'Información personal',
  enrollment_id_details_name_bold_text: '<b>Nombre:</b>',
  enrollment_id_details_email_bold_text:
    '<b>Dirección de correo electrónico:</b>',
  enrollment_id_details_address_bold_text: '<b>Dirección: </b>',
  enrollment_id_details_editicon_text: 'Editar',
  USPS_facilities_located_around: 'Oficinas de Correos cerca de:',
  more_info_text_invalid: ' ',
  address_list_view_less: 'ver menos',
  address_list_view_more: 'Ver más',
  address_list_parking_lot: 'Aparcamiento: ',
  address_list_post_office: ' Post Office',
  address_list_office_hours: 'Cerrado',
  address_list_today_office_hours: 'Abierto hasta ',
  address_list_tomorrow_label: 'Mañana',
  address_list_parking_lot_yes: 'Si',
  address_list_parking_lot_no: 'No',
  alternate_address_heading_text:
    'Encuentre ubicaciones alternativas de oficinas de correos participantes para la verificación de identidad de su reclamo de desempleo.',
  alternate_address_helper_text: `Si su dirección actual no coincide con su identificación gubernamental, tiene la opción de explorar ubicaciones de oficinas postales participantes alternativas adaptadas a sus preferencias. Esto garantiza un proceso de verificación de identidad fluido para su reclamo de desempleo con USPS.`,
  alternate_address_helper_text2:
    'Ingrese una dirección para encontrar una oficina postal cerca de usted.',
  alternate_address_address_search_button:
    'Encontrar otras ubicaciones de USPS',
  alternate_address_form_submit_button: 'Someter',
  alternate_address_email_button:
    'Envíame un correo electrónico con la ubicación actualizada',
  alternate_address_email_sent_message:
    '<b>¡Se han enviado ubicaciones actualizadas!</b>',
  alternate_address_email_sent_error_message:
    '<b>Error al enviar el correo electrónico.</b>',
  alternate_address_noPostOffice_header_message:
    'No hay oficinas postales participantes dentro de un radio de 50 millas de su dirección para completar la verificación de identificación en persona con USPS en este momento.',
  alternate_address_noPostOffice_para1_message:
    'Si cree que la dirección proporcionada anteriormente es incorrecta, actualícela para ubicar las oficinas de correos cercanas.',
  alternate_address_noPostOffice_para2_message:
    'Si la información que ha ingresado es correcta, puede elegir un método de verificación diferente.',
  USPS_facilities_located_around_count_text1: 'Hay ',
  USPS_facilities_located_around_count_text2:
    ' participantes dentro de un radio de 50 millas de ',
  Monday: 'Lunes',
  Tuesday: 'Martes',
  Wednesday: 'Miércoles',
  Thursday: 'Jueves',
  Friday: 'Viernes',
  Saturday: 'Sábado',
  Sunday: 'Domingo',

  // AddressSearch component
  personal_info_heading_text: 'Información personal',
  heading_helper_text:
    'Escriba su nombre y dirección exactamente como aparecen en su documento de identidad emitido por el gobierno estatal o federal.',
  form_required_info_text:
    'Los campos obligatorios a continuación están marcados con un asterisco',
  input_first_name: 'Nombre',
  input_first_name_placeholder: 'Introduzca su nombre aquí',
  input_last_name: 'Apellido',
  input_last_name_placeholder: 'Introduzca su apellido aquí',
  input_email: 'Dirección de correo electrónico',
  input_email_placeholder: 'Introduzca su e-mail aquí',
  input_street_address_1: 'Dirección/nombre de la calle',
  input_street_address_1_placeholder: 'Introduzca su dirección aquí',
  input_street_address_2: 'Numero de apartamento, suite, unidad, etc.',
  input_street_address_2_placeholder: 'Introduzca su dirección aquí',
  input_city_name_1: 'Ciudad',
  input_city_name_placeholder: 'Introduzca su ciudad aquí',
  input_state_name: 'Estado',
  input_state_name_placeholder: 'Seleccione su estado',
  Select_State_DropdownText: 'Seleccione su estado',
  input_zipcode: 'Código postal',
  input_zipcode_placehoder: 'Introduzca su código Postal aquí',
  form_submit_button: 'Someter',
  different_verification_method_link:
    'Seleccione un método de verificación diferente',

  // usps-description component
  required_Identity_documents: ' ',
  acceptable_forms_of_ID: 'Documentos de Identificación aceptables:',
  State_drivers_license: 'Licencia de conducir',
  state_id_card: 'Tarjeta de identificación emitida por su estado',
  uniformed_id_cards:
    ' Tarjeta de identificación de dependiente militar o Tarjeta de Marino Mercante de la Guardia Costera de Estados Unidos (necesita otra identificación)',
  secondary_id: 'Pasaporté estadunidense (necesita otra identificación)',
  required_docs_heading:
    'Utilizamos la dirección declarada en su identificación para verificar su identidad.',
  required_docs_para:
    'Si no tiene dirección o su dirección es diferente de la que esta declarada en su documento de identificación, necesita presentar comprobante de domicilio, por ejemplo:',
  reqired_docs_list_lease:
    'Contrato de arrendamiento, hipoteca, escritura del domicilio o el fideicomiso',
  reqired_docs_list_voter: 'Tarjeta de registro de votantes',
  reqired_docs_list_vehical: 'Tarjeta de registro del vehículo',
  reqired_docs_list_home: 'Póliza de seguro de hogar o vehículo',
  postOffice_text: 'The Post Office<sup>TM</sup>',
  postOffice_span: 'no aceptará:',
  list_social_security: 'Tarjetas de seguro social',
  list_credit_card: `Tarjetas de crédito o facturas "de utilidades"`,
  list_school_id: 'Identificación de escuelas',
  list_medical_marijuana: 'Tarjetas de marihuana medicinal',
  list_concealed_licence: 'Licencias de portacion de armas ocultas',
  list_expierd_forms: 'Formas de identificación caducadas (expiradas)',
  uicp_state_title: ' ',
  uicp_state_timing_para: ' ',
  uicp_state_number: ' ',
  redirect_email: ' ',
  usps_contact: ' ',
  usps_state_title: ' ',
  usps_state_number: ' ',
  usps_state_timing_para: ' ',

  // ErrorPage component
  invalid_url_heading: 'URL invalida',
  invalid_url_subheading:
    'Parece que la URL que ingresó no es válida o ya no existe.',
  invalid_url_para1:
    'No podemos ubicar su número de verificación de identificación asociado con su solicitud de desempleo.{br}',
  invalid_url_para2:
    'Es posible que la URL en su confirmación se haya ingresado incorrectamente. Vuelva a verificar la URL y vuelva a intentarlo.{br}',
  invalid_url_para3: ' ',
  hitawall_heading: 'Tenemos problemas para conectar con USPS',
  hitawall_login_subheading:
    'La verificación de su identidad con Login.gov no está disponible actualmente.', // spanish texts to be update later
  hitawall_usps_heading: 'Tenemos problemas para conectar con USPS',
  hitawall_usps_subheading:
    'La verificación de su identidad con USPS no está disponible actualmente.',
  hitawall_para1:
    'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado.{br}{br}La verificación de su identidad con Login.gov no está disponible actualmente.',
  hitawall_para2:
    'Vuelva a intentarlo más tarde o elija un método de verificación diferente. Gracias por su paciencia y comprensión.',
  out_of_network_heading:
    'No se encontró una oficina del correo postale participantes',
  out_of_network_subheading:
    'No hay Post Offices participantes dentro de las 50 millas de su dirección para completar la verificación de identificación en persona con USPS en este momento.',
  out_of_network_para1: 'Por favor, elija un método de verificación diferente',
  out_of_network_no_participate_para1: 'No hay Post Offices<sup>TM</sup>',
  out_of_network_no_participate_para2:
    '  participantes dentro de las 50 millas de su dirección para completar la verificación de identificación en persona con USPS en este momento.',
  out_of_network_para2: 'Por favor, elija un método de verificación diferente.',
  out_of_network_more_info_text: ' ',
  moreinfo_link1_500: ' ',
  moreinfo_link2_500: ' ',
  moreinfo_link3_500: ' ',

  // spanish texts to be updated
  error404_heading: 'Tenemos problemas para conectar',
  error404_subheading: '404 - Pagina no encontrada',
  error404_para1:
    'Parece que la URL que ingresó no es válida o ya no existe. Verifique su URL o regrese a la página de ',
  choice_page_link: 'opciones de verificación de identificación.',
  error404_more_info_text: ' ',
  more_info_text_404: ' ',
  error404_para2:
    'Si necesita ayuda para verificar su identidad para su reclamo de desempleo o necesita ayuda para verificar su identidad:',
  login_gov_help_txt: 'Para obtener ayuda con Login.gov:',
  login_link: 'https://www.login.gov/contact/',
  more_info_text1: 'Más información',
  moreinfo_link1: 'Find my local Division of Workforce Services office',
  moreinfo_link2: 'Division of Workforce Services Unemployment Insurance',
  moreinfo_link3:
    'Your Unemployment Insurance Handbook — Division of Workforce Services [PDF]',

  // Address Modal
  address_modal_header: 'Enter new address',
  address_modal_subheader:
    'Search for USPS facilities to verify your identity in a different location.',
  address_modal_zip: 'Zip',
  address_modal_search_button: 'Search',

  // Language Modal
  header_usdol_menu: 'MENU',
  language_label_header: 'Idioma',
  language_label_english: 'Inglés',
  language_label_spanish: 'Español',
  language_modal_header: 'Cambio de idioma',
  language_modal_para1:
    'Al hacer clic en Continuar y cambiar el idioma, esta página se recargará y perderá toda la información que haya ingresado en esta página.',
  language_modal_para2:
    'Cualquier información ingresada en páginas anteriores se ha guardado y no se perderá.',
  language_continue_button: 'Continuar',
  language_back_button: 'Retroceder',

  // FE Validation Errors
  fn_mainError: 'Por favor, introduzca su nombre',
  fn_subError:
    'Caracteres permitidos A-Z {br} También puede incluir guiones, apóstrofes y espacios',
  ln_mainError: 'Por favor introduzca su apellido ',
  ln_subError:
    'Caracteres permitidos A-Z {br} También puede incluir espacios, puntos, guiones y apóstrofes',
  email_mainError:
    'Su correo electrónico debe tener un formato de correo electrónico válido',
  email_subError:
    'Ingrese su dirección de correo electrónico en formato: sunombre@ejemplo.com',
  addr1_mainError: 'Por favor introduzca su dirección legal válida',
  addr1_subError:
    'Caracteres permitidos A-Z, 1-9 {br} También puede incluir espacios, guiones, apóstrofes, puntos, barra inclinada “/” y signo de número “#”',
  addr2_mainError: ' ',
  addr2_subError:
    'Caracteres permitidos A-Z, 1-9 {br} También puede incluir espacios, guiones, apóstrofes, puntos, barra inclinada “/” y signo de número “#”',
  city_mainError: 'Por favor introduzca su ciudad',
  city_subError:
    'Caracteres permitidos A-Z {br} También puede incluir espacios, puntos, guiones y apóstrofes',
  zipcode_mainError: 'Por favor introduzca su código postal',
  zipcode_subError: 'Hasta 10 dígitos numéricos, incluyendo los guiones',
  state_mainError: 'Seleccione su estado de las opciones a continuación',
  // Header Component
  banner_header_text: 'Un sitio web oficial del Gobierno de Estados Unidos',
  banner_button_text: 'Por esto sabes que lo es',
  header_media_block: 'Los sitios web oficiales usan .gov',
  header_media_block_body: 'Los sitios web .gov seguros usan HTTPS',
  header_usdol_title: 'Departamento del Trabajo de EE. UU.',
  banner_lock_description_two:
    'significa que se ha conectado en forma segura al sitio web .gov. Comparta información sensible solo en sitios web oficiales y seguros.',
  banner_lock_description_main: 'un candado cerrado',
  banner_lock_title: 'Cerrar',
  banner_lock_description_one: 'o',
  header_media_block_one: 'candado',
  header_media_block_two: 'Un sitio web',
  header_media_block_two_inner: 'Un',
  header_media_block_three:
    'pertenece a una organización oficial de gobierno en Estados Unidos.',

  // Footer Component
  footer_nav_federal: 'Gobierno federal',
  footer_nav_labor: 'Departamento del Trabajo',
  footer_nav_about: 'Acerca del sitio',
  footer_connect_font: 'Conectarse con el Departamento del Trabajo',
  footer_findit: 'Mapa del sitio',
  footer_website_policies: 'Avisos importantes del sitio web',
  footer_privacy_notice: 'Declaración de privacidad y seguridad',
  footer_dol_name_and_seal: 'Departamento del Trabajo de EE. UU.',

  footer_secondary_link_white_house: 'casa Blanca',
  footer_secondary_link_corona_virus: 'Recursos de coronavirus',
  footer_secondary_link_storm_recovery:
    'Asistencia para la Recuperación de Tormentas Severas e Inundaciones',
  footer_secondary_link_disaster_recovery:
    'Asistencia de recuperación de desastres',
  footer_secondary_link_no_fear_act: 'Datos de la Ley Sin Miedo',
  footer_secondary_link_special_counsel:
    'Oficina de Asesoría Especial de EE. UU.',
  footer_secondary_link_about_dol: 'Acerca de DOL',
  footer_secondary_link_guidance_search: 'Búsqueda de orientación',
  footer_secondary_link_inspector_general: 'Oficina del Inspector General',
  footer_secondary_link_dol_newsletter: 'Suscríbase al boletín de DOL',
  footer_secondary_link_read_newsletter: 'Lea el boletín informativo del DOL',
  footer_secondary_link_accountability_status:
    'Enlace de estado de responsabilidad de emergencia',
  footer_secondary_link_a_to_z_index: 'Índice de la A a la Z',
  footer_secondary_link_information_act: 'Acta de Libertad de Información',
  footer_secondary_link_disclaimers: 'Descargos de responsabilidad',
  footer_secondary_link_accessibility_statement: 'Declaración de accesibilidad',

  // Workforce Component
  workforce_heading_text:
    'Verificación de identidad para reclamo de desempleo en una oficina local',
  workforce_appointment_text: ' ',
  workforce_para1: ' ',
  workfore_para2: ' ',
  workfore_para3: ' ',
  workforce_location_link: 'Find my local Employment Department',

  // Error Component
  invalid_login:
    'Parece que hubo un error cuando intentaba verificar su identidad con Login.gov.',
  error_invalid_title: 'Inicio de sesión incorrecto',
  error_invalid_login_para1:
    '¿Quieres intentarlo de nuevo? Inicie sesión. Si tiene problemas para acceder a su cuenta, intente olvidar su contraseña.',
  error_invalid_login_link: 'Inicia sesión',
  error_invalid_login_para2:
    'Si tiene problemas con Login.gov, elija un método de verificación diferente para encontrar la mejor opción para verificar su identidad para su reclamo de desempleo.',
  error_invalid_login_forgot: 'Olvidaste tu contraseña',
  error_invalid_login_para3:
    'Si usted tiene preguntas sobre los beneficios de desempleo o si necesita ayuda para verificar su identidad puede contactarnos a través de:',
  error_500_title: 'Tenemos problemas para conectar',
  error_500_heading:
    'La verificación de su identidad no está disponible actualmente.',
  error_service_unavailable_heading:
    'Los servicios para verificar su identidad no están disponibles actualmente',
  error_500_para1:
    'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado. ',
  error_500_para2: 'Gracias por su paciencia y comprensión. ',
  hitawall_login_heading: 'Tenemos problemas para conectar con Login.gov',
  out_of_network_para_text1:
    'Por favor, elija un método de verificación diferente. {br}{br}',
  workforce_more_info_text: 'Más información',
  error_invalid_login_para7: ' ',
  fraud_modal_header:
    'Bienvenido a la página de verificación de identificación del Departamento de Trabajo de los Estados Unidos.',
  fraud_modal_para1: ' ',
  fraud_modal_para2: ' ',
  fraud_modal_button: 'Entiendo',
  hitawall_para7: ' ',
  heading_helper_text1: ' ',
  heading_helper_text2: ' ',
  uicp_state_email: ' ',
  online_radiobutton_doc_list_text_second: ' ',
  uicp_more_info_text: ' ',
  moreinfo_confirmation_link1: ' ',
  moreinfo_logout_link: ' ',
  logout_next_step_text: ' ',
  error_invalid_login_para4: ' ',
  error_invalid_login_para5: ' ',
  error_invalid_login_para6: ' ',
  more_info_text2: ' ',
  error_invalid_login_moreinfo_link1: ' ',
  error_invalid_login_moreinfo_link2: ' ',
  hitawall_para3: ' ',
  hitawall_para4: ' ',
  hitawall_more_info_text: ' ',
  hitawall_para5: ' ',
  hitawall_para6: ' ',
  fraud_modal_para1_usps: ' ',
  usps_state_email: ' ',
  moreinfo_link1_invalid: ' ',
  moreinfo_link2_invalid: ' ',
  invalid_url_para4: ' ',
  out_of_network_para3: ' ',
  out_of_network_para4: ' ',
  para1_link_text: ' ',
  para3_link_text: ' ',
  workforce_moreinfo_link1: ' ',
  workforce_moreinfo_link2: ' ',
  workforce_moreinfo_link3: ' ',
  error_500_more_info_text1: ' ',
  error_500_more_info_text2: ' ',
  error_500_para3: ' ',
  workforce_radiobutton_doc_list_extra_title: ' ',
  workforce_radiobutton_doc_list_extra_text: ' ',
};
